import tracking from '../lib/tracking';

export default function() {
    $(function() {
        if ($('#cookie-notifications-btn').length) {

            $('#cookie-notifications-btn').click(function () {
                if ($(this).attr('data-track')) {
                    tracking.ckEventUrl($(this).attr('data-track'));
                }

                let time = 525600 * 60000, // one year in miliseconds
                    vdate = new Date();

                vdate.setTime(vdate.getTime() + time);

                let key = $(this).attr('data-key');
                let cookieName = key ? 'cookie_notification_accepted_' + key : 'fc_cookie_notification_accepted';
                document.cookie = cookieName + '=true; expires=' + vdate + '; path=/';

                $('.cookie-notifications').hide();
                $('body').removeClass('euro-message-active');
            });
        }

        let $cookieBannerModal = $('#cookieBannerModal');
        if ($cookieBannerModal.length) {
            $cookieBannerModal.find('.j-accept, .j-deny').on('click', function() {
                tracking.ckEventUrl($(this).attr('data-url'));

                let time = 525600 * 60000; // one year in miliseconds
                let vdate = new Date();
                vdate.setTime(vdate.getTime() + time);

                let selection = $(this).hasClass('j-accept') ? 'accept' : 'deny';
                let cookie = $(this).parents('.button-wrapper').attr('data-cookie');
                document.cookie = cookie + '=' + selection + '; expires=' + vdate.toUTCString() + '; path=/';

                $cookieBannerModal.addClass('hide');
            });
        }
    });
}